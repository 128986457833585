/**
 *  This file must contain all variables that need to be overridden
 *  on SCSS style files.
 *
 *  All colors must be an hexadecimal with format #RRGGBB (RED, GREEN, BLUE)
 *  and no alpha channel.
*/
@use "@primavera/themes/_pri-theme";

// LIGHT THEME
// LIGHT THEME
$theme-highlight-lt: #0D0D82;
$theme-100-lt: #304CD1;
$theme-80-lt: #4D68EB;
$theme-60-lt: #6E86FF;
$theme-40-lt: #A1B0FF;
$theme-20-lt: #D4DBFF;
$theme-10-lt: #EDF0FF;

$header-dark-lt: #060740;
$header-100-lt: #0D0F81;
$header-80-lt: #3D3F9B;
$header-60-lt: #6465AF;

// DARK THEME
$theme-highlight-dt: #8EA2F9;
$theme-100-dt: #728AF7;
$theme-80-dt: #6176D2;
$theme-60-dt: #5060AD;
$theme-40-dt: #455395;
$theme-20-dt: #39447B;
$theme-10-dt: #222949;

$header-dark-dt: #0E1011;
$header-100-dt: #171A1C;
$header-80-dt: #454849;
$header-60-dt: #747677;