@use "@primavera/themes/_pri-theme";

.is-app-initializing .main-loader {
    background-color: #F5F5F5;

    .loader-animation::after {
        box-shadow: none;
        background: linear-gradient(270deg, #4D68EB, #0D0D82);
        background-size: 200% 200%;
        -webkit-animation: load 15s cubic-bezier(0, 0.9, 0.45, 1) forwards, bg-anim 11s ease-in-out infinite;
        animation: load 15s cubic-bezier(0, 0.9, 0.45, 1) forwards, bg-anim 11s ease-in-out infinite;
    }

    .loader-logo {
        background-image: url(/assets/images/loader_logo.svg) !important;
    }

    .loader-animation {
        background: pri-theme.$white;
        box-shadow: 0px 5px 15px -4px #828282;
        max-height: 30px;
        margin-top: 40px;
        padding: 5px;
    }
}

@-webkit-keyframes bg-anim {
    0% {
        background-position: 0% 51%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 51%
    }
}

@keyframes bg-anim {
    0% {
        background-position: 0% 51%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 51%
    }
}