html,
body {
	width: 100%;
	height: 100%;
	margin: 0;

	*,
	:after,
	:before {
		box-sizing: border-box;
	}
}

.is-app-initializing {
	app-root {
		display: none;
	}

	.main-loader {
		// **************************************************
		// To customize the loader change these variables
		// **************************************************
		$loader-theme-color-primary: $theme-100-lt;
		$loader-theme-color-secondary: $theme-10-lt;

		$page-background-color: $loader-theme-color-primary;

		$loader-space-between-logo-and-progressbar: 50px;

		$loader-logo-url: url('/assets/images/product_menu_logo.svg');
		$loader-logo-height: 10%;
		$loader-logo-min-height: 30px;
		$loader-logo-max-height: 100px;

		$loader-progressbar-thickness: 4%;
		$loader-progressbar-min-thickness: 20px;
		$loader-progressbar-max-thickness: 40px;
		$loader-progressbar-width: 90vw;
		$loader-progressbar-max-width: 500px;
		$loader-progressbar-animation: cubic-bezier(
			0,
			0.9,
			0.45,
			1
		); // Generate a curve using https://cubic-bezier.com/
		$loader-progressbar-duration: 15s;
		$loader-progressbar-border-radius: 30px;
		$loader-progressbar-color: $loader-theme-color-primary;

		$loader-progressbar-wrapper-color: $loader-theme-color-secondary;
		$loader-progressbar-wrapper-padding: 6px;

		// Add/remove progressbar stops by changing keyframes below
		@keyframes load {
			0% {
				width: 10px;
			}

			100% {
				width: 95%;
			}
		}

		// ********************************************************
		// Can't touch this 🔨⌛
		//
		// Making changes below may break things.
		// Proceed with caution.
		// ********************************************************
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100vw;
		height: 100vh;
		z-index: 2001;
		background-color: $page-background-color;

		.loader-logo {
			height: $loader-logo-height;
			min-height: $loader-logo-min-height;
			max-height: $loader-logo-max-height;
			width: 100%;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			background-image: $loader-logo-url;
		}

		.loader-animation {
			position: relative;
			display: flex;
			justify-content: flex-start;
			height: $loader-progressbar-thickness;
			min-height: $loader-progressbar-min-thickness;
			max-height: $loader-progressbar-max-thickness;
			width: $loader-progressbar-width;
			max-width: $loader-progressbar-max-width;
			align-items: center;
			margin-top: $loader-space-between-logo-and-progressbar;
			border-radius: $loader-progressbar-border-radius;
			padding: $loader-progressbar-wrapper-padding;
			background: $loader-progressbar-wrapper-color;
		}

		.loader-animation::after {
			content: '';
			height: 100%;
			width: 0;
			box-shadow: 0 10px 40px -10px #000001;
			background: $loader-progressbar-color;
			border-radius: $loader-progressbar-border-radius;
			animation: load $loader-progressbar-duration $loader-progressbar-animation forwards;
		}
	}
}
