/**
 * ACCORDING TO PRIMAVERA DESIGN SYSTEM:
 * https://design.primaverabss.com/5db52be82/p/0695e1-estilo
 *
 * These variables must follow the defined on the PRIMAVERA Design System,
 * including their naming.
 */
/**
 *  This file must contain all variables that need to be overridden
 *  on SCSS style files.
 *
 *  All colors must be an hexadecimal with format #RRGGBB (RED, GREEN, BLUE)
 *  and no alpha channel.
*/
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}
html *,
html :after,
html :before,
body *,
body :after,
body :before {
  box-sizing: border-box;
}

.is-app-initializing app-root {
  display: none;
}
.is-app-initializing .main-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 2001;
  background-color: #304CD1;
}
@keyframes load {
  0% {
    width: 10px;
  }
  100% {
    width: 95%;
  }
}
.is-app-initializing .main-loader .loader-logo {
  height: 10%;
  min-height: 30px;
  max-height: 100px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("/assets/images/product_menu_logo.svg");
}
.is-app-initializing .main-loader .loader-animation {
  position: relative;
  display: flex;
  justify-content: flex-start;
  height: 4%;
  min-height: 20px;
  max-height: 40px;
  width: 90vw;
  max-width: 500px;
  align-items: center;
  margin-top: 50px;
  border-radius: 30px;
  padding: 6px;
  background: #EDF0FF;
}
.is-app-initializing .main-loader .loader-animation::after {
  content: "";
  height: 100%;
  width: 0;
  box-shadow: 0 10px 40px -10px #000001;
  background: #304CD1;
  border-radius: 30px;
  animation: load 15s cubic-bezier(0, 0.9, 0.45, 1) forwards;
}

/**
 * ACCORDING TO PRIMAVERA DESIGN SYSTEM:
 * https://design.primaverabss.com/5db52be82/p/0695e1-estilo
 *
 * These variables must follow the defined on the PRIMAVERA Design System,
 * including their naming.
 */
.is-app-initializing .main-loader {
  background-color: #F5F5F5;
}
.is-app-initializing .main-loader .loader-animation::after {
  box-shadow: none;
  background: linear-gradient(270deg, #4D68EB, #0D0D82);
  background-size: 200% 200%;
  -webkit-animation: load 15s cubic-bezier(0, 0.9, 0.45, 1) forwards, bg-anim 11s ease-in-out infinite;
  animation: load 15s cubic-bezier(0, 0.9, 0.45, 1) forwards, bg-anim 11s ease-in-out infinite;
}
.is-app-initializing .main-loader .loader-logo {
  background-image: url(/assets/images/loader_logo.svg) !important;
}
.is-app-initializing .main-loader .loader-animation {
  background: #ffffff;
  box-shadow: 0px 5px 15px -4px #828282;
  max-height: 30px;
  margin-top: 40px;
  padding: 5px;
}

@-webkit-keyframes bg-anim {
  0% {
    background-position: 0% 51%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 51%;
  }
}
@keyframes bg-anim {
  0% {
    background-position: 0% 51%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 51%;
  }
}